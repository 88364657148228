export const landscapePhotos = [
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes01_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes01_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes02_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes02_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes28_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes28_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes04_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes04_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes05_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes05_LOW.jpg",
    width: 3,
    height: 4
  },
  { 
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes06_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes06_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes07_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes07_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes08_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes08_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Water_Features/waterfall08_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Water_Features/waterfall08_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes09_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes09_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes10_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes10_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes11_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes11_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes12_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes12_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes13_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes13_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes18_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes18_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes15_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes15_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes16_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes16_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes17_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes17_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes14_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes14_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes19_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes19_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes20_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes20_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes21_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes21_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes22_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes22_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes23_HIGH.jpg",
    thumbnail: "hhttps://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes23_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes24_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes24_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes25_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes25_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes26_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes26_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes27_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes27_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes03_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes03_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes30_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Landscapes/landscapes30_LOW.jpg",
    width: 4,
    height: 3
  }
];
  