export const travelPhotos = [
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel01_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel01_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel03_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel03_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel02_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel02_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel04_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel04_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel05_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel05_LOW.jpg",
    width: 4,
    height: 3
  },
  { 
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel06_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel06_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel07_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel07_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel08_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel08_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel09_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel09_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel10_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel10_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel11_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel11_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel12_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel12_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel13_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel13_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel14_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel14_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel15_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel15_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel16_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel16_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel17_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel17_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel18_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel18_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel19_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel19_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel20_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Travel/travel20_LOW.jpg",
    width: 3,
    height: 4
  }
];
  