export const seaLifePhotos = [
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife01_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife01_LOW.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife02_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife02_LOW.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife03_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife03_LOW.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife04_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife04_LOW.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife05_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife05_LOW.jpg",
    width: 3,
    height: 2
  },
  { 
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife06_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife06_LOW.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife07_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife07_LOW.jpg",
    width: 2,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife08_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife08_LOW.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife09_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife09_LOW.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife10_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife10_LOW.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife11_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife11_LOW.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife12_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife12_LOW.jpg",
    width: 3,
    height: 2
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife13_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/SeaLife/marineLife13_LOW.jpg",
    width: 3,
    height: 2
  }
];
  