export const landingPhotos = [
    {
      src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/birdsHome_HIGH.jpg",
      width: 3,
      height: 2,
      alt: "Birds",
      overlay: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/birdsHome_Overlay.jpg"
    },
    {
      src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/insectsHome_HIGH.jpg",
      width: 3,
      height: 2,
      alt: "Insects",
      overlay: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/insectsHome_Overlay.jpg" 
    },
    {
      src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/landscapes30_HIGH.jpg",
      width: 3,
      height: 2,
      alt: "Landscapes",
      overlay: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/landscapesHome_Overlay.jpg" 
    },
    {
      src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/mammalsHome_HIGH.jpg",
      width: 4,
      height: 3,
      alt: "Mammals",
      overlay: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/mammalsHome_Overlay.jpg" 
    },
    {
      src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/waterfallsHome_HIGH.jpg",
      width: 3,
      height: 4,
      alt: "Water-Features",
      overlay: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/waterfallsHome_Overlay.jpg"
    },
    {
      src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/panoramasHome_HIGH.jpg",
      width: 11,
      height: 3,
      alt: "Panoramas",
      overlay: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/panoramasHome_Overlay.jpg" 
    },
    {
      src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/plantLifeHome_HIGH.jpg",
      width: 3,
      height: 2,
      alt: "PlantLife",
      overlay: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/plantLifeHome_Overlay.jpg"
    },
    {
      src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/travelHome_HIGH.jpg",
      width: 3,
      height: 4,
      alt: "Travel",
      overlay: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/travelHome_Overlay.jpg" 
    },
    {
      src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/seaLifeHome_HIGH.jpg",
      width: 3,
      height: 2,
      alt: "SeaLife",
      overlay: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/seaLifeHome_Overlay.jpg"
    }, 
    {
      src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/reptilesHome_HIGH.jpg",
      width: 3,
      height: 2,
      alt: "Reptiles",
      overlay: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Home/reptilesHome_Overlay.jpg"
    }
  ];
  