export const reptilesPhotos = [
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles01_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles01_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles02_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles02_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles11_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles11_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles04_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles04_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles05_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles05_LOW.jpg",
    width: 4,
    height: 3
  },
  { 
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles06HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles06_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles07_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles07_HIGH.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles08_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles08_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles09_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles09_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles10_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles10_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles12_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles12_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles13_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles13_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles14_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles14_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles15_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles15_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles16_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles16_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles17_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles17_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles18_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles18_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles19_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Reptiles/reptiles19_LOW.jpg",
    width: 4,
    height: 3
  }
];
  