export const birdsPhotos = [
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds01_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds01_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds02_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds02_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds03_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds03_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds04_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds04_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds05_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds05_LOW.jpg",
    width: 4,
    height: 3
  },
  { 
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds06_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds06_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds07_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds07_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds08_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds08_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds24_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds24_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds10_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds10_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds22_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds22_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds12_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds12_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds13_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds13_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds14_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds14_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds15_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds15_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds16_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds16_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds17_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds17_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds18_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds18_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds19_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds19_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds20_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds20_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds21_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds21_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds23_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds23_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds25_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds25_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds26_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds26_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds27_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds27_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds28_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds28_LOW.jpg",
    width: 3,
    height: 4
  },
  { 
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds29_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds29_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds30_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds30_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds31_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds31_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds32_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds32_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds33_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds33_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds34_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds34_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds35_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds35_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds36_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds36_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds37_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds37_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds38_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds38_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds39_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds39_LOW.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds40_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds40_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds41_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds41_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds42_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds42_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds44_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds44_LOW.jpg",
    width: 4,
    height: 3
  },
  {
    src: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds45_HIGH.jpg",
    thumbnail: "https://js-react-photo-portfolio.s3-us-west-2.amazonaws.com/PhotoPortfolio/Birds/birds45_LOW.jpg",
    width: 4,
    height: 3
  }
];
  